import React from 'react';
import '../../assets/styles/home.css';
import Grid from '@mui/material/Grid';
import { BrowserView, MobileView } from 'react-device-detect';


const UserProfileCardComponent = (props) => {
    return (
        <Grid item xs={12} md={12}>
            <div className="main-card">
                <img className='Profile-Picture' src={props?.picture} alt="" />
                <div className="card-title">

                    <BrowserView>
                        <div className="name-surname-desktop">
                            {props?.firstName}
                            <br />
                            {props?.lastName}
                        </div>
                        <div className="person-title-desktop">
                            {props?.businessName}
                            <br />
                            {props?.businessPosition}
                        </div>
                    </BrowserView>
                    <MobileView>
                        <div className="name-surname">
                            {props?.firstName}
                            <br />
                            {props?.lastName}
                        </div>
                        <div className="person-title">
                            {props?.businessName}
                            <br />
                            {props?.businessPosition}
                        </div>
                    </MobileView>
                </div>
            </div>
        </Grid>
    );
}

export default UserProfileCardComponent;