import React from 'react';
import '../../assets/styles/home.css';
import Grid from '@mui/material/Grid';
import logo from '../../assets/images/logo.png';
import appstore1 from '../../assets/images/appstore1.png';
import googleplay1 from '../../assets/images/googleplay1.png';

const FooterCompanent = (props) => {
    return (
        <Grid container spacing={3} justifyContent="center" alignItems="center">

            <Grid item xs={4} md={4}>
                <a href={`https://apps.apple.com/us/app/beetinq/id1608291189`}>
                    <center>
                        <img src={appstore1} width={50} style={{ marginTop: '5px', marginBottom: '5px' }} />
                    </center>
                </a>
            </Grid>
            <Grid item xs={4} md={4}>
                <a href={'https://beetinq.me/beetinqcard'}>

                    <center>
                        <img src={logo} width={120} style={{ marginTop: '5px', marginBottom: '5px' }} />
                    </center>

                </a>
            </Grid>

            <Grid item xs={4} md={4}>
                <a href={`https://play.google.com/store/apps/details?id=com.beetinq`}>
                    <center>
                        <img src={googleplay1} width={50} style={{ marginTop: '5px', marginBottom: '5px' }} />
                    </center>
                </a>
            </Grid>
        </Grid>
    );
}

export default FooterCompanent;

