import React from 'react';
import '../../assets/styles/home.css';
import Grid from '@mui/material/Grid';

const UrlIconCardCompanent = (props) => {
    return (
        <Grid item xs={4} md={4}>
            <a href={`${props.value}`}>
                <div style={{
                    color: 'white',
                    backgroundColor: '#2D2D2D',
                    padding: '10px 0',
                    borderRadius: '10px',
                    textAlign: 'center',
                }}>
                    <center>
                        <img src={`${props.icon}`} style={{ height: '50px', width: '50px', margin: '5px' }}></img>
                    </center>
                    <p style={{ fontSize: "10pt", padding: '5px 0', }} >{`${props.name}`}</p>
                </div>
            </a>
        </Grid>
    );
}

export default UrlIconCardCompanent;

