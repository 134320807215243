import React from 'react';
import '../../assets/styles/home.css';
import Grid from '@mui/material/Grid';


const UrlTextCardCompanent = (props) => {
    return (
        <>
            <Grid item xs={12} md={12}>
                <a href={`${props.value}`}>
                    <div style={{
                        color: 'white',
                        backgroundColor: '#2D2D2D',
                        padding: '10px 0',
                        borderRadius: '10px',
                        textAlign: 'center',
                    }}>
                        <p style={{ fontSize: "12pt", padding: '5px 0', }}>{`${props.title}`}</p>
                    </div>
                </a>
            </Grid>
        </>
    );
}

export default UrlTextCardCompanent;

