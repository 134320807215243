import React from 'react';

import * as mime from 'react-native-mime-types';
import VCard from 'vcard-creator';
import Grid from '@mui/material/Grid';
import { getDoc, doc, updateDoc, collection, addDoc, Timestamp } from 'firebase/firestore';
import Button from '@mui/material/Button';
import { db } from '../../config/firebase.js';
import '../../assets/styles/home.css';
import SaveIcon from '@mui/icons-material/SaveAlt';







const AddPhoneBookCardCompanent = (props) => {

    const [imagePhoto, setImagePhoto] = React.useState("");
    const [imageLogo, setImageLogo] = React.useState("");


    const downloadVfcFile = async () => {

        const userVCard = new VCard();

        const docPhoto = doc(db, 'digitalcards/' + props?.cardId + '/imagebase64/photo');
        const docSnapPhoto = await getDoc(docPhoto);
        if (docSnapPhoto.exists()) {
            setImagePhoto(docSnapPhoto.data().image);
            userVCard.addPhoto(docSnapPhoto.data().image);
            console.log('docSnapPhoto' + docSnapPhoto.data().image);
        }


        const docLogo = doc(db, 'digitalcards/' + props?.cardId + '/imagebase64', 'logo');
        const docSnapLogo = await getDoc(docLogo);
        if (docSnapLogo.exists()) {
            setImageLogo(docSnapLogo.data().image);
            userVCard.addLogo(docSnapLogo.data().image);
            console.log('docSnapLogo: ' + docSnapLogo.data().image);
        }


        userVCard.addName(props?.surName, props?.name);

        userVCard.addCompany(props?.businessName, props?.businessPosition);

        props?.contactData?.map((list) => {
            if (list.code == "mail") {
                userVCard.addEmail(list.datas);
            } else if (list.code == "address") {
                userVCard.addAddress(list.datas);
            } else if (list.code == "phone") {
                userVCard.addPhoneNumber(list.datas);
            } else if (list.code == "link") {
                if (list.open == "url") {
                    userVCard.addSocial(list.value, list.name, list.title)
                } else {
                    userVCard.addNote(list.value.replaceAll(/<[^>]+>/g, '').replaceAll("&nbsp;", " "))
                }
            } else {
                userVCard.addSocial(list.value, list.name, list.title)
            }

        })

        console.log("VCard: " + props?.contactData?.toString());

        userVCard.addURL("https://beetinq.me/" + props?.digitalCardId, "work",);
        userVCard.addSocial("https://beetinq.me/" + props?.digitalCardId, "beetinq.me", "beetinq.me/" + props?.digitalCardId)
        userVCard.setFilename(props?.fullName);
        if (props?.about != "") {
            userVCard.addNote(props?.about);
        }
        userVCard.setFormat('vcard');
        mime.lookup('vcf');
        mime.extension('text/x-vcard');




        const docRef = await addDoc(collection(db, 'digitalcardsstatistics/' + props?.digitalCardId + '/adds'), {
            id: "",
            createDate: Timestamp.fromDate(new Date()),
            startDate: Timestamp.fromDate(new Date()),
        });
        console.log("DocId: " + docRef.id);
        await updateDoc(doc(db, 'digitalcardsstatistics/' + props?.digitalCardId + '/adds', docRef.id), {
            id: docRef.id
        });
        const element = document.createElement("a");
        const file = new Blob([userVCard.toString()],
            { type: 'text/x-vcard;charset=utf-8' });
        element.href = URL.createObjectURL(file);
        element.download = props?.name + " " + props?.surName + " " + props?.phone + ".vcf";
        document.body.appendChild(element);
        element.click();
    }

    return (
        <Grid item xs={12} md={12}>
            <a onClick={downloadVfcFile}>
                <div style={{
                    color: 'white',
                    backgroundColor: '#2D2D2D',
                    padding: '10px 0',
                    borderRadius: '10px',
                    textAlign: 'center',
                }}>

                    <Button variant="text" startIcon={<SaveIcon />} style={{ fontSize: "12pt", padding: '5px 0', color: 'white', }}>{props?.countryCode === props?.countryTrCode ? (
                        "Telefon Rehberine Kaydet"
                    ) : (
                        "Save Your Phone Book"
                    )}</Button>



                </div>
            </a>
        </Grid>
    );
}

export default AddPhoneBookCardCompanent;