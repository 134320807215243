import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDzqoiOCg5YKYq0saCiDnXctzCxNmQn_cg",
    authDomain: "beetinq-app.firebaseapp.com",
    projectId: "beetinq-app",
    storageBucket: "beetinq-app.appspot.com",
    messagingSenderId: "1051757144478",
    appId: "1:1051757144478:web:273709f7b042cdc7508e8f",
    measurementId: "G-Z70J1E511T"
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);

