import React from 'react';
import '../../assets/styles/home.css';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ModalIconCardCompanent = (props) => {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Grid item xs={4} md={4}>
                <a onClick={handleOpen} target="_blank">
                    <div style={{
                        color: 'white',
                        backgroundColor: '#2D2D2D',
                        padding: '10px 0',
                        borderRadius: '10px',
                        textAlign: 'center',
                    }}>
                        <center>
                            <img src={`${props.icon}`} style={{ height: '50px', width: '50px', margin: '5px' }}></img>
                        </center>
                        <p style={{ fontSize: "10pt", padding: '5px 0', }}>{`${props.name}`}</p>
                    </div>
                </a>
            </Grid>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {`${props.title}`}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div dangerouslySetInnerHTML={{ __html: props.value }} />
                    </Typography>
                </Box>
            </Modal>
        </>
    );
}

export default ModalIconCardCompanent;
