import { BrowserRouter as Router, Routes, Route, useParams } from "react-router-dom"
import HomePage from "./pages/home/HomePage"
import WebPage from "./pages/web"


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/:cardId" element={<HomePage />} />
        <Route path="/" element={<HomePage />} />
      </Routes>
    </Router>
  );
}

export default App;
