import * as React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

import { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route, useParams, useNavigate } from "react-router-dom"
import { query, where, getDocs, doc, getDoc, updateDoc, collection, orderBy, addDoc, Timestamp } from 'firebase/firestore';
import { useCallback } from 'react';
//import { getDocs, doc, getDoc, addDoc, updateDoc, collection, Timestamp } from 'firebase/firestore';


import { db } from '../../config/firebase.js';
import '../../assets/styles/home.css';

import UserProfileCardComponent from '../../components/cards/userProfileCardComponent.js';
import BusinessProfileCardComponent from '../../components/cards/businessProfileCardComponent.js';
import UrlIconCardCompanent from '../../components/cards/urlIconCardCompanent.js';
import ModalIconCardCompanent from '../../components/cards/modalIconCardCompanent.js';
import UrlTextCardCompanent from '../../components/cards/urlTextCardCompanent.js';
import ModalTextCardCompanent from '../../components/cards/modalTextCardCompanent.js';

import AddPhoneBookCardCompanent from '../../components/cards/addPhoneBookCardCompanent.js';
import FooterCompanent from '../../components/footer/footer.js';
import AppBarCompanent from '../../components/header/appbar.js';
import ThemeDark from '../../components/theme/themeDark.js';



const HomePage = () => {

    const [cardData, setCardData] = React.useState([]);
    const [contactData, setContactData] = React.useState([]);
    let { cardId } = useParams();
    let countryTrCode = "tr";
    const navigate = useNavigate();

    useEffect(() => {
        getCardInfo();
        submitViews();
    }, []);



    const submitViews = async (e) => {
        const docRef = await addDoc(collection(db, 'digitalcardsstatistics/' + cardId + '/views'), {
            id: "",
            createDate: Timestamp.fromDate(new Date()),
            startDate: Timestamp.fromDate(new Date()),
        });
        console.log("DocId: " + docRef.id);
        await updateDoc(doc(db, 'digitalcardsstatistics/' + cardId + '/views', docRef.id), {
            id: docRef.id
        });
    };

    const getCardInfo = async (e) => {
        const docRefCard = doc(db, "digitalcards", cardId);
        console.log("docRefCard: " + docRefCard);
        const docSnapCard = await getDoc(docRefCard);
        console.log("docSnapCard: " + docSnapCard);
        if (docSnapCard.exists()) {
            setCardData(docSnapCard.data());
            console.log(docSnapCard.data());

            const collectionRefContact = collection(db, 'digitalcards/' + cardId + '/digitalcardaccounts')
            const qContact = query(collectionRefContact, where("state", "==", true), orderBy("order", "asc"))
            const doc_refs = await getDocs(qContact);
            const resContact = [];
            doc_refs.forEach(contact => {
                resContact.push({
                    id: contact.id,
                    open: contact.data().open,
                    icon: contact.data().icon,
                    name: contact.data().name,
                    code: contact.data().code,
                    type: contact.data().type,
                    style: contact.data().style,
                    value: contact.data().value,
                    title: contact.data().title,
                    datas: contact.data().datas,
                })
            });
            setContactData(resContact);
        } else {
            console.log("No such document!");
        }
    }



    return (
        <ThemeProvider theme={ThemeDark}>

            <CssBaseline />

            <AppBarCompanent />

            <Container component="main" maxWidth="sm" sx={{ mb: 4 }} style={{ background: '#171C1F' }}>

                <React.Fragment>

                    <Grid container spacing={3} justifyContent="center" alignItems="center">


                        {cardData?.businessState ? (
                            <BusinessProfileCardComponent
                                picture={cardData?.picture}
                                firstName={cardData?.firstName}
                                lastName={cardData?.lastName}
                                businessPosition={cardData?.businessPosition}
                                businessName={cardData?.businessName}
                            />
                        ) : (
                            <UserProfileCardComponent
                                picture={cardData?.picture}
                                firstName={cardData?.firstName}
                                lastName={cardData?.lastName}
                                businessPosition={cardData?.businessPosition}
                                businessName={cardData?.businessName}
                            />
                        )}


                        {cardData?.about !== "" ? (
                            <Grid item xs={12} md={12}>
                                <div style={{
                                    color: 'white',
                                    backgroundColor: '#2D2D2D',
                                    padding: '15px 0',
                                    borderRadius: '10px',
                                    textAlign: 'center',
                                }}>
                                    <p style={{ fontSize: "12pt", padding: '5px 0', }} >{cardData?.about}</p>
                                </div>
                            </Grid>
                        ) : (<div></div>)}

                        <Grid item xs={12} md={12}></Grid>

                    </Grid>



                    <Grid container spacing={12} justifyContent="center" alignItems="center">

                        <AddPhoneBookCardCompanent
                            cardId={cardId}
                            surName={cardData?.lastName}
                            name={cardData?.firstName}
                            imagebase64={cardData?.imagebase64}
                            businessName={cardData?.businessName}
                            businessPosition={cardData?.businessPosition}
                            mail={cardData?.mail}
                            phone={cardData?.phone}
                            address={cardData?.address}
                            contactData={contactData}
                            url={cardData?.url}
                            about={cardData?.about}
                            digitalCardId={cardData?.digitalCardId}
                            addContactCount={cardData?.addContactCount}
                            picture={cardData?.picture}
                        />

                    </Grid>

                    <Grid item xs={12} md={12}><br></br></Grid>

                    <Grid container spacing={3} justifyContent="center" alignItems="center">

                        {contactData?.map((list) => (
                            list.type === "icon" ? (
                                list.open === "url" ? (
                                    <UrlIconCardCompanent
                                        icon={`${list.icon}`}
                                        value={`${list.value}`}
                                        name={list.name}
                                    />
                                ) : (
                                    <ModalIconCardCompanent
                                        icon={`${list.icon}`}
                                        title={`${list.title}`}
                                        value={`${list.value}`}
                                        name={list.name}
                                    />
                                )
                            ) : (
                                list.open === "url" ? (
                                    <UrlTextCardCompanent
                                        icon={`${list.icon}`}
                                        title={`${list.title}`}
                                        value={`${list.value}`}
                                        name={list.name}
                                    />
                                ) : (
                                    <ModalTextCardCompanent
                                        icon={`${list.icon}`}
                                        title={`${list.title}`}
                                        value={`${list.value}`}
                                        name={list.name}
                                    />
                                )
                            )
                        ))}

                    </Grid>

                    <Grid item xs={12} md={12}><br></br></Grid>

                    <FooterCompanent />

                </React.Fragment>

            </Container>

        </ThemeProvider >
    );


}


export default HomePage;